@font-face {
  font-family: "Apax Regular";
  src: url('../fonts/Optimo-Apax/Apax-Regular/Apax-Regular.eot') format('embedded-opentype'),
      url('../fonts/Optimo-Apax/Apax-Regular/Apax-Regular.woff2') format('woff2'),
      url('../fonts/Optimo-Apax/Apax-Regular/Apax-Regular.woff') format('woff'),
      url('../fonts/Optimo-Apax/Apax-Regular/Apax-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Apax Bold";
  src: url('../fonts/Optimo-Apax/Apax-Bold/Apax-Bold.eot') format('embedded-opentype'),
      url('../fonts/Optimo-Apax/Apax-Bold/Apax-Bold.woff2') format('woff2'),
      url('../fonts/Optimo-Apax/Apax-Bold/Apax-Bold.woff') format('woff'),
      url('../fonts/Optimo-Apax/Apax-Bold/Apax-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: bold;
}
