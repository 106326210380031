.fc {
    &,
    &.size-h5 .fc-button,
    &.size-h5 table,
    &.size-h5 .fc-toolbar-title {
        font-size: initial;
    }

    .fc-event,
    .fc-h-event,
    .fc-button-primary{
        background-color: #124734 !important;
        border: 1px solid #124734 !important;

        &,
        .fc-event-main {
            color: #FFF !important;
        }

        &:not(:disabled):not(.unavailable):hover {
            background-color: #000 !important;
            border-color: #000 !important;
        }
    }

    .fc-list-event:hover td {
        background-color: #000 !important;
        border-color: #000 !important;
    }

    .fc-event.unavailable,
    .fc-h-event.unavailable,
    .fc-day-past .fc-event,
    .fc-day-past .fc-h-event {
        background-color: #F3F4F3 !important;
        border-color: #F3F4F3 !important;

        &,
        .fc-event-main {
            color: rgba(#124734, .5) !important;
        }
    }

    .fc-toolbar.fc-header-toolbar {

        .fc-toolbar-chunk {
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;
        }

        .fc-customTitle-button {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .fc-toolbar-title {
            font-size: 1.1em !important;
            line-height: 1em;
            margin-left: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            text-transform: capitalize;
        }

        .fc-button-group {
            margin-left: 0 !important;
            margin-top: 15px;
        }

        .fc-today-button, .fc-customDateSelect-button {
            margin-top: 15px;
        }
    }

    .fc-daygrid-day.fc-day-today {
        background-color: #F3F4F3 !important;
    }

    .fc-button {

        &:disabled {
            opacity: 0.2 !important;
        }

        &-primary {

            &.fc-customTitle-button {
                display: block;
                padding: 0;
                margin: 0 0 15px;
                width: auto;
                text-align: left;
                cursor: default !important;
                pointer-events: none !important;

                &,
                &:hover {
                    background: none !important;
                    border: none !important;
                    color: #000 !important;
                }
            }
        }
    }

    .fc-day {
        &-past,
        &-other {
            .fc-daygrid-day-top {
                opacity: .3;
            }

            .fc-h-event {
                opacity: .5;
            }
        }
        &-past {
            &,
            * {
                pointer-events: none !important;
            }
        }
    }

    .fc-event {
        font-size: 14px;
        line-height: 19px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        &.fc-daygrid-event {
            white-space: normal;
        }
    }

    .fc-daygrid-event-dot {
        display: none;
    }

    .fc-h-event,
    .fc-daygrid-dot-event {
        .fc-event-time,
        .fc-event-title {
            padding: 1px;
        }

        .fc-event-title {
            font-weight: 700;

            span {
                font-weight: initial;
            }
        }
    }

    .fc-button.fc-customDateSelect-button {
        transition: none !important;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        display: none;
    }
}


@media (min-width: 1200px) {
    .formation + .formation-calendar {
        margin-top: -50px;
    }

    .fc {
        flex-direction: row !important;
        margin-left: -15px;
        margin-right: -15px;

        & > * {
            padding-left: 15px;
            padding-right: 15px;
        }

        .fc-toolbar.fc-header-toolbar {
            margin-bottom: 0 !important;
            -ms-flex-align: start !important;
            align-items: flex-start !important;
            flex-direction: column;
            justify-content: start;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;

            .fc-today-button, .fc-customDateSelect-button {
                margin-left: 0;
            }
        }

        .fc-view-harness {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}

@media (max-width: 1199px) {
    .fc {
        .fc-toolbar.fc-header-toolbar {
            display: block;
            margin-bottom: 15px !important;
            -ms-flex-align: end !important;
            align-items: flex-end !important;

            .fc-toolbar-title {
                min-width: 140px;
            }

            .fc-button {
                width: auto;

                &.fc-today-button {
                    margin-left: auto;
                }
            }

            .fc-today-button, .fc-customDateSelect-button {
                margin-left: auto;
            }
        }
    }
}

@media (max-width: 500px) {
    .fc {
        .fc-toolbar.fc-header-toolbar {
            .fc-today-button, .fc-customDateSelect-button {
                margin-left: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
