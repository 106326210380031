/*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
* {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -ms-overflow-style: none;
}

*::-webkit-scrollbar {
    display: none;
}

/* NO FIREFOX COMPATIBILITIES */

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: default;
}

img {
    border: none;
    max-width: 100%;
}

a[href^="mailto:"] {
    white-space: nowrap !important;
}

.padding-xs, .padding-s, .padding-m, .padding-l, .padding-xl {
    width: 100%;
}

.padding-xs {
    height: 15px;
}

.padding-s {
    height: 30px;
}

.padding-m {
    height: 50px;
}

.padding-l {
    height: 100px;
}

.padding-xl {
    height: 150px;
}

.h-auto {
    height: auto !important;
}

hr {
    border: none !important;
    display: block;
    position: relative;
    width: 100%;
    max-width: 100% !important;
    height: 1px;
    margin: 75px auto !important;
}

ul, ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul {

    &.ul-disc {
        padding-left: 18px;

        li {
            list-style: disc;
        }
    }

    &.ul-table {
        border-top: 1px solid;

        li {
            position: relative;
            padding: 16px 0 12px;
            border-bottom: 1px solid;
        }
    }
}

ol {
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    margin: 0;
    padding: 0;

    li {
        padding: 12px 0 12px 60px;

        span {
            display: block;
            position: relative;

            &:before {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                top: 50%;
                left: -60px !important;
                height: 40px;
                width: 40px;
                line-height: 38px;
                text-align: center;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateX(0) translateY(-50%);
            }
        }
    }
}

video, header, aside, nav, main, section, footer {
    display: block;
}

.content-wrapper,
section,
.z-index-up {
    position: relative;
    z-index: 1;
}

.z-index-up {
    z-index: 100;
}

section:not(.formation) {
    width: 100%;
    overflow: hidden;
}

figure, figcaption {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

figcaption {
    margin-top: 8px;
}

blockquote {
    margin: 0;
    padding: 0;
}

blockquote p:before,
blockquote p:after {
    display: inline-block;
}

blockquote p:before {
    content: "\00ab";
}

blockquote p:after {
    display: none;
    content: "\00bb";
    margin-left: 0.1em;
}

html[lang="de-DE"] blockquote p:before {
    content: "\201E";
}

html[lang="de-DE"] blockquote p:after {
    content: "\201D";
}

.anchor-parent {
    position: relative;
    display: block;
}

.anchor {
    position: absolute;
    left: 0;
    top: -50px;
    height: 0;
    width: 100%;
}

.oembed {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* FORMAT 16:9 */
}

.bg-section,
.bg-link,
.bg-overlay:after,
.bg-overlay-light:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.bg-overlay:after {
    content: "";
    display: block;
    z-index: 1;
}

.bg-section {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-link {
    border: none;
    display: block;
    cursor: pointer;
    z-index: 100;
}

.bg-section-video video,
.bg-section .ytplayer-container,
.bg-section iframe,
.oembed iframe {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    height: 100%;
}

.bg-section-video video {
    width: auto;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
}

html.user-ie .bg-section-video video,
html.user-edge .bg-section-video video {
    min-width: 0;
}

html.user-ie .bg-section-video.width video,
html.user-edge .bg-section-video.width video {
    width: 100%;
    height: auto;
}

html.user-ie .bg-section-video.height video,
html.user-edge .bg-section-video.height video {
    min-width: 0;
    height: 100%;
}

.btn-corpo,
.btn-rounded {
    border: none;
    display: inline-block;
    position: relative;
    padding: 20px 35px;
    line-height: normal;
    text-align: center !important;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
}

.btn-block {
    display: block;
    width: 100% !important;
}

.btn-rounded {
    padding: 8px 12px 6px;
}

.btn-arrow {
    background: url(../images/icon-arrow-link-black.svg) no-repeat top 8% right 1px;
    background-size: auto 92%;
    display: inline-block;
    padding-right: 40px;
    cursor: pointer;

    &.bottom {
        background-image: url(../images/icon-arrow-link-bottom-black.svg);
    }
}

.list-icons {
    margin: -8px;
}

.list-icons > [class*="col"] {
    padding: 8px;
}

.border-radius,
.btn-rounded,
.mobile-menu .sub-menu a,
div.wpcf7 .link-item .ajax-loader {
    border-radius: 2000px !important;
    -webkit-border-radius: 2000px !important;
    -moz-border-radius: 2000px !important;
}

.no-border-radius,
input, textarea, select, button {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
}

/*--------------------------------------------------------- COLORS & BACKGROUNDS ---------------------------------------------------------*/
.bg-white,
hr,
#main-menu > ul > li .sub-menu,
.mobile-menu .sub-menu a,
.swiper-pagination-bullet,
.project-card-container,
.dropdown-menu,
.btn-corpo,
.btn-rounded,
.row-form.row-form-textarea > label,
div.wpcf7 .link-item .ajax-loader {
    background-color: #FFF !important;
}

.bg-light,
html,
body,
.line-light,
.fancybox-bg,
.category-faq.active,
div.wpcf7 div.wpcf7-response-output {
    background-color: #F3F4F3 !important;
}

.bg-corporate,
.menu-item a::before,
.download-item-container:hover,
.swiper-pagination-bullet.green {
    background-color: #124734 !important;
}

.bg-black {
    background-color: #000 !important;
}

.services-card-img .bg-overlay,
.team-card-image.bg-section:not(.no-image) {
    background-color: rgba(0, 0, 0, .1) !important;
}

.bg-overlay {
    background-color: rgba(0, 0, 0, .4) !important;
}

.bg-overlay.bg-overlay-light {
    background-color: rgba(255, 255, 255, .4) !important;
}

.bg-overlay:after {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#59000000', endColorstr='#00000000', GradientType=1);
}

.slider-quotes .bg-overlay:after {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .35) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .35) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .35) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#59000000', GradientType=1);
}

.bg-overlay.bg-overlay-light:after {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

.project-card-img:after {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#1a000000', GradientType=0);
}

.row-form input, .row-form select, .row-form textarea, .row-search input,
section.introduction + section.content-block.block-img,
section.introduction + section.project {
    background-color: transparent !important;
}

input:-webkit-autofill,
input:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-internal-autofill-selected,
select:-webkit-autofill,
select:-internal-autofill-selected {
    -webkit-text-fill-color: #124734 !important;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

.border-white,
.swiper-pagination-bullet span,
.mobile-menu + .mobile-menu {
    border-color: #FFF !important;
}

.services-container,
.border-light {
    border-color: #F3F4F3 !important;
}

.border-black,
.dropdown-label,
.row-form.row-search input {
    border-color: #000 !important;
}

.border-corporate,
.row-form input,
.row-form textarea,
.row-form select {
    border-color: #DBE5DB !important;
}

.swiper-pagination-bullet.green span,
.row-form-select label:before {
    border-color: #124734 !important;
}

.btn-corpo.white,
.color-white,
.download-item-container:hover {
    color: #FFF !important;
}

.color-corporate,
html.no-touchevents .menu-item a:hover,
a,
html.no-touchevents #main a.color-black:hover,
.menu-item.hover-step1 a,
.btn-corpo,
form,
input, textarea, select,
.fancybox-button svg,
.fancybox-close-small,
html.no-touchevents .resume-short:hover,
html.no-touchevents .resume-long-wrapper:hover,
#footer,
#footer a,
.fc-toolbar-title{
    color: #124734 !important;
}

.color-corporate-opacity {
    color: rgba(#124734, .5) !important;
}

.color-black,
.menu-item a,
.mobile-menu .menu-item a {
    color: #000 !important;
}

.color-red {
    color: #e82017 !important;
}

.color-light,
.current .filter-item,
html.no-touchevents .sub-menu:hover a:not(:hover),
body.single-service.safety .sub-menu li:not(.menu-item-object-safety) a:not(:hover),
body.single-service.solutions .sub-menu li:not(.menu-item-object-solutions) a:not(:hover),
body.single-service.equipment .sub-menu li:not(.menu-item-object-equipment) a:not(:hover),
body.single-service.architecture .sub-menu li:not(.menu-item-object-architecture) a:not(:hover),
.row-form.focus-label label:not(.no-effect) {
    color: rgba(34, 40, 34, .4) !important;
}

.accordion-item.active header * {
    color: #222822 !important;
}

.row-search input::placeholder {
    color: #000;
    opacity: 1;
}

.row-search input:-ms-input-placeholder {
    color: #000;
}

.row-search input::-ms-input-placeholder {
    color: #000;
}

.row-search.focus-label input::placeholder {
    color: rgba(0, 0, 0, 0);
    opacity: 0;
}

.row-search.focus-label input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0);
}

.row-search.focus-label input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0);
}

body.scrolling-to-bottom:not(.page-scrolled):not(.menu-opened-step2) #header,
body.page-scrolled.scrolling-to-top:not(.menu-opened-step2) #header,
body.page-scrolled.scrolling-to-top:not(.menu-opened-step2):not(.end-page) .contact-mobile {
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
}

html.no-touchevents .project-card:hover,
html.no-touchevents .resume-long-wrapper:hover {
    -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .06);
    -moz-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .06);
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .06);
}

#main-menu > ul > li .sub-menu,
.dropdown-menu {
    -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .07);
    -moz-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .07);
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .07);
}

html.no-touchevents .download-item-container.bg-corporate:hover {
    -webkit-box-shadow: 0 10px 35px 0 rgba(34, 40, 34, .2);
    -moz-box-shadow: 0 10px 35px 0 rgba(34, 40, 34, .2);
    box-shadow: 0 10px 35px 0 rgba(34, 40, 34, .2);
}

/*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
input, textarea, select, button,
.regular {
    font-family: "Apax Regular", Arial, sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-align: left;
}

.text-quote blockquote,
.link-item a,
.link-item-nolist a,
.link-item > span input,
.mobile-menu .menu-item a,
b, strong, .bold,
h1, .style-h1,
h2, .style-h2,
h3, .style-h3,
h4, .style-h4,
h5, .style-h5,
h6, .style-h6,
.fc.size-h5 .fc-button.fc-customTitle-button {
    font-family: "Apax Bold", Arial, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
}

h1, .style-h1 {
    font-size: 65px;
    letter-spacing: .01em;
    line-height: 1.2em;
}

h2, .style-h2,
.link-item-nolist a:not(.style-h4),
.link-item a:not(.style-h4) {
    font-size: 44px;
    letter-spacing: .01em;
    line-height: 1.2em;
}

h3, .style-h3,
.fc.size-h5 .fc-button.fc-customTitle-button {
    font-size: 35px;
    letter-spacing: .01em;
    line-height: 1.2em;
}

h4, .style-h4,
body,
.link-item > span input,
section.content-column .link-item a,
section.project .link-item a,
section.formation .link-item a,
#main-menu ul li,
.project-card-container span {
    font-size: 25px;
    letter-spacing: .01em;
    line-height: 1.3em;
}

.header-project .style-h4 {
    line-height: 1.1em;
}

h5, .style-h5,
.size-h5,
.pre-footer,
.adress-footer,
.resume-long-container p,
.dropdown-label,
.question-container,
.row-form label,
.row-form.row-search input,
div.wpcf7 div.wpcf7-response-output {
    font-size: 18px;
    letter-spacing: .01em;
    line-height: 1.3em;
}

h6, .style-h6,
.formation-price small,
.btn-corpo,
.dropdown-menu,
.row-form input, .row-form select, .row-form textarea {
    font-size: 14px;
    letter-spacing: .01em;
    line-height: 1.5em;
}

small, .small,
.row-form span.wpcf7-not-valid-tip {
    font-size: 11px;
    letter-spacing: .01em;
    line-height: 1.5em;
}

sup {
    font-size: 65%;
    line-height: 0;
}

i, em,
.italic {
    font-style: italic !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

a {
    text-decoration: none !important;
}

.uppercase {
    text-transform: uppercase !important;
}

p,
h1, .style-h1,
h2, .style-h2,
h3, .style-h3,
h4, .style-h4,
h5, .style-h5,
h6, .style-h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.margin-top {
    margin-top: 2em;
}

p:not(:first-child) {
    margin-top: 1em;
}

.team-card-item p:not(:first-child) {
    margin-top: 0.5em;
}

h1:not(:last-child), .style-h1:not(:last-child),
h2:not(:last-child), .style-h2:not(:last-child),
h3:not(:last-child), .style-h3:not(:last-child),
h4:not(:last-child), .style-h4:not(:last-child),
h5:not(:last-child), .style-h5:not(:last-child),
h6:not(:last-child), .style-h6:not(:last-child),
.team-card-item p:not(:last-child) {
    margin-bottom: 0.5em;
}

.title-section, .title-section:not(:last-child),
.formation-page h2,
p:not(:last-child) {
    margin-bottom: 1em;
}

/*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
body {
    padding-top: 195px;
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 80px 0;
    z-index: 99966;
}

body.page-scrolled #header {
    padding-top: 40px;
    padding-bottom: 40px;
}

body.page-scrolled.scrolling-to-bottom:not(.menu-opened-step2) #header {
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
    transform: translate(0, -100%);
}

#header-logo {
    width: 100%;
    height: 35px;
}

#logo,
#logo img {
    display: block;
    width: 100%;
    height: 100%;
}

#logo img {
    margin-top: -0.34em;
}

#footer-menu > ul > li,
#main-menu > ul > li,
#contact-header > ul > li {
    display: inline-block;
    position: relative;
}

#footer-menu > ul > li:not(:last-child),
#main-menu > ul > li:not(:last-child) {
    margin-right: 75px;
}

#contact-header > ul > li:not(:last-child) {
    margin-right: 30px;
}

#main-menu > ul > li .sub-menu {
    display: none;
    position: absolute;
    left: -25px;
    top: 100%;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px);
}

#main-menu > ul > li .sub-menu,
#main-menu > ul > li .sub-menu a {
    padding-top: 8px;
    padding-bottom: 6px;
}

#main-menu > ul > li .sub-menu a {
    display: block;
    padding-left: 25px;
    padding-right: 25px;
}

.menu-item.hover-step1 .sub-menu {
    display: block !important;
}

.menu-item.hover-step2 .sub-menu {
    -webkit-transform: translate(0, 0) !important;
    -moz-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    -o-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

#contact-header a,
#contact-header img {
    width: 26px;
    height: 26px;
}

#main-menu,
#contact-header {
    height: 35px;
    width: 100%;
    position: relative;
}

#contact-header a {
    display: block;
}

.menu-item a {
    position: relative;
    overflow: hidden;
}

#main-menu .current_page_item a::before,
.menu-item a::before {
    content: '';
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 0;
}

#mobile-menu .menu-item a::before,
.sub-menu .menu-item a::before,
#footer .menu-item a::before {
    display: none;
}

#main-menu .current_page_item a::before,
.menu-item a:hover::before,
.menu-item.hover-step2 a::before {
    height: 200px;
}

.contact-mobile {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 0;
    -webkit-transform: translate(0, 100%);
    -moz-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    -o-transform: translate(0, 100%);
    transform: translate(0, 100%);
    z-index: 99966;
}

body.menu-opened-step2 .contact-mobile {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}

#menu-burger-wrapper,
#menu-burger {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

#menu-burger-wrapper {
    width: 70px;
    height: 100%;
    display: inline-block;
    right: 0;
    top: 50%;
    cursor: pointer;
    z-index: 8888;
}

#menu-burger {
    right: 15px;
    width: 40px;
    height: 18px;
}

.menu-line {
    position: relative;
    width: 100%;
    height: 2px;
}

.menu-line:not(:first-child) {
    margin-top: 6px;
}

.menu-line:last-child {
    width: 80%;
}

#mobile-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 85px;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 15px 0 110px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate(0, 30px) translate3d(0, 0, 0);
    -moz-transform: translate(0, 30px) translate3d(0, 0, 0);
    -ms-transform: translate(0, 30px) translate3d(0, 0, 0);
    -o-transform: translate(0, 30px) translate3d(0, 0, 0);
    transform: translate(0, 30px) translate3d(0, 0, 0);
    z-index: 99933;
}

.mobile-menu {
    margin: 0 -15px;
}

.mobile-menu + .mobile-menu {
    border-top: 1px solid;
    margin-top: 30px;
    padding-top: 30px;
}

.mobile-menu li a,
.mobile-menu .sub-menu {
    display: block;
    padding: 15px;
}

.mobile-menu .sub-menu {
    margin: -15px -5px -5px;
    line-height: 1em;
}

.mobile-menu .sub-menu li {
    display: block;
    margin: 5px;
}

.mobile-menu .sub-menu a {
    display: inline-block;
    padding: 12px 20px 8px;
}

#menu-background-burger {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
    z-index: 99900;
}

body.menu-opened-step1 #mobile-menu {
    display: block;
}

body.menu-opened-step2 #mobile-menu {
    -webkit-transform: translate(0, 0) translate3d(0, 0, 0);
    -moz-transform: translate(0, 0) translate3d(0, 0, 0);
    -ms-transform: translate(0, 0) translate3d(0, 0, 0);
    -o-transform: translate(0, 0) translate3d(0, 0, 0);
    transform: translate(0, 0) translate3d(0, 0, 0);
}

body.menu-opened-step2 #menu-background-burger {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

body.menu-opened-step2 .menu-line-1 {
    -webkit-transform: translate(0, 9px) rotate(45deg);
    -moz-transform: translate(0, 9px) rotate(45deg);
    -ms-transform: translate(0, 9px) rotate(45deg);
    -o-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
}

body.menu-opened-step2 .menu-line-2 {
    -webkit-transform: translate(0, 1px) rotate(-45deg);
    -moz-transform: translate(0, 1px) rotate(-45deg);
    -ms-transform: translate(0, 1px) rotate(-45deg);
    -o-transform: translate(0, 1px) rotate(-45deg);
    transform: translate(0, 1px) rotate(-45deg);
}

/*--------------------------------------------------------- SERVICES FOOTER ---------------------------------------------------------*/
.services-container {
    padding: 60px 70px;
    border: solid 1px;
    min-height: 100%;
}

.footer-services figure * {
    height: 100%;
}

.footer-services figure .bg-section {
    width: calc(100% + ((100vw - 1600px) / 2));
    left: auto;
    right: 0;
}

.footer-services-wrapper {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
}

.footer-services-item {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

/*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
input, textarea, select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

section.text-bg-fullwidth {
    padding-top: 200px;
}

section,
#footer .content-wrapper {
    padding-top: 150px;
}

section.title {
    padding-top: 100px;
}

section.gallery,
section.project + section.content-block,
section.content-block-fw + section.content-block,
section.content-block + section.content-block,
section.slider-quotes,
section.bg-light + section.bg-light,
section:not(.bg-light):not(.text-bg-fullwidth):not(.introduction) + section:not(.bg-light):not(.text-bg-fullwidth):not(.introduction):not(.footer-services),
section.introduction + section.content-block.block-img,
section.introduction + section.resume-long,
section.introduction + section.project,
section.resume-long + section.bg-light {
    padding-top: 0 !important;
}

section.text-bg-fullwidth {
    padding-bottom: 200px;
}

section,
.text-footer-services {
    padding-bottom: 150px;
}

#project-intro,
.project-highlights > .content-wrapper > .row:first-child,
.team-filter-wrapper {
    padding-bottom: 100px;
}

section.introduction + section.team-list .team-filter-wrapper,
#footer .content-wrapper {
    padding-bottom: 50px;
}

section.gallery:last-child,
section.slider-quotes {
    padding-bottom: 0px !important;
}

section.introduction + section.content-block.block-img,
section.introduction + section.resume-long,
section.introduction + section.project {
    margin-top: -150px;
}

.content-column + .resume-long.bg-light {
    margin-top: -100px;
    padding-top: 0;
}

.map + .bg-light {
    z-index: 0;
}

.my-0,
.mt-0 {
    margin-top: 0 !important;
}

.my-0,
.mb-0 {
    margin-bottom: 0 !important;
}

.mx-0,
.ml-0 {
    margin-left: 0 !important;
}

.mx-0,
.mr-0 {
    margin-right: 0 !important;
}

.line-light {
    height: 1px;
    width: 100%;
    position: relative;
    margin: 90px 0;
}

/*--------------------------------------------------------- TEXTE & ARRIERE-PLAN ---------------------------------------------------------*/
.list-link-box {
    position: relative;
    margin-top: 350px;
    height: auto;
}

.link-button {
    margin-top: 25px;
    position: relative;
}

/*--------------------------------------------------------- LINK BUTTON ---------------------------------------------------------*/
.link-item-nolist {
    position: relative;
    margin-top: 50px;
}

.link-item,
.link-item a {
    display: inline-block;
    position: relative;
}

.link-item {
    padding-right: 50px;
}

form .text-right .link-item {
    padding-right: 0;
}

span.arrow-mobile,
.link-item::after,
.link-item-nolist a.green::after,
.link-item > span.green::after,
.link-item a.green::after,
.link-item-nolist a.white::after,
.link-item a.white::after,
.link-item-nolist a.black::after,
.link-item a.black::after {
    content: '';
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -1px;
    margin-left: -20px;
    width: 90px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    transform-origin: right center;
    z-index: 0;
}

.link-item.bottom a::after {
    -webkit-transform: rotate(90deg) translate(0, 50%) !important;
    -moz-transform: rotate(90deg) translate(0, 50%) !important;
    transform: rotate(90deg) translate(0, 50%) !important;
}

.link-item::after {
    position: absolute;
    top: 100%;
}

span.arrow-mobile {
    display: inline-block;
    top: auto;
    left: auto;
    width: 60px;
    margin-top: 0px;
    margin-left: 0px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

html.no-touchevents .link-item-nolist:hover a::after,
html.no-touchevents .link-item:hover a::after,
html.no-touchevents .bg-link:hover + * .link-item a::after {
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    -o-transform: translate(10px, -50%);
    transform: translate(10px, -50%);
}

.link-item.green::after,
.link-item-nolist a.green::after,
.link-item > span.green::after,
.link-item a.green::after {
    background-image: url(../images/icon-arrow-link-green.svg);
}

.link-item-nolist a.white::after,
.link-item a.white::after {
    background-image: url(../images/icon-arrow-link-white.svg);
}

span.arrow-mobile,
.link-item-nolist a.black::after,
.link-item a.black::after {
    background-image: url(../images/icon-arrow-link-black.svg);
}

.link-item.green::after {
    left: 0;
    width: 0px;
    margin-left: 0;
    position: relative;
    cursor: pointer;
    margin-top: 35px;
    display: block;
}

html.no-touchevents .resume-short:hover .link-item.green::after {
    width: 40px !important;
}

.link-item > span {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 35px;
}

.link-item > span input {
    display: block;
    position: relative;
    background: none;
    padding: 9px 65px 10px 0;
    cursor: pointer;
    z-index: 100;
}

.wpcf7 .link-item > span::after {
    top: 0;
    left: auto;
    right: 0;
    margin-left: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

html.no-touchevents .wpcf7 .link-item > span:hover::after,
html.no-touchevents .wpcf7 .link-item > span:hover .ajax-loader {
    -webkit-transform: translate(10px, 0);
    -moz-transform: translate(10px, 0);
    -ms-transform: translate(10px, 0);
    -o-transform: translate(10px, 0);
    transform: translate(10px, 0);
}

/*--------------------------------------------------------- CONTENU INTRODUCTION ---------------------------------------------------------*/
section.introduction.padding-blocks {
    padding-bottom: 250px;
}

section.introduction.padding-resume {
    padding-bottom: 300px;
}

section.introduction + section.content-block.block-img .bg-section {
    height: auto;
    top: 180px;
    bottom: 0;
}

.bg-resume-long {
    margin-top: 200px;
}

/*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
.logo-wrapper.format_l {
    margin-left: 85px;
    margin-right: 85px;

    & > .row {
        margin-top: -60px;
        margin-bottom: -60px;

        .logo-item {
            width: 180px;
            min-height: 80px;
            margin: 60px 3.333%;
            position: relative;
        }
    }
}

.logo-wrapper.format_l .logo-item img {
    max-width: 150px;
    max-height: 80px;
}

.logo-wrapper.format_l .logo-item img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logo-wrapper,
.logo-slider-wrapper {
    position: relative;
    height: 100%;
}

.logo-slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.logo-item {
    width: 26.666%;
    margin: 20px 3.333%;

    img {
        display: block;
        max-height: 100px;
    }
}

.logo-nb-1,
.logo-nb-2{

    .logo-item {
        max-width: 40%;

        img {
            max-height: 200px;
        }
    }
}


/*--------------------------------------------------------- IMAGE ---------------------------------------------------------*/
.img-item img {
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .flex-column-reverse-mobile {
        flex-direction: column-reverse;
    }
}

/*--------------------------------------------------------- CONTENU EN COLONNES ---------------------------------------------------------*/
section.content-column > .content-wrapper > .row {
    margin-top: -25px;
    margin-bottom: -25px;
}

.paragraph-box {
    margin-top: 25px;
    margin-bottom: 25px;
}

/*--------------------------------------------------------- CONTENU EN BLOCK + DOWNLOAD ---------------------------------------------------------*/
.content-block-fw.full-width-item {
    padding: 200px 0;
}

.dl-wrapper {
    margin-top: -15px;
    margin-bottom: -15px;
}

.dl-items {
    padding-top: 15px;
    padding-bottom: 15px;
}

.download-item-container {
    min-height: 100%;
    padding: 50px 70px;
    position: relative;
}

.download-item-container .link-item-box {
    position: relative;
    width: 100%;
    height: 35px;
}

.download-item-container .link-item {
    position: absolute;
}

.download-item-container.one-item,
.block-item {
    padding: 50px 135px;
}

.block-item {
    margin: 15px;
}

.block-item ul li {
    list-style-type: disc;
    /* line-height: 35px; */
}

.grid-block {
    width: auto;
    margin: -15px;
}

.grid-item-block,
.grid-sizer-block {
    width: 50%;
}

.block-img-item-wrapper {
    position: relative;
    overflow: visible;
    margin-bottom: 30px;
}

.block-img-item,
.block-img-item figure {
    position: relative;
    height: 100%;
}

.block-img-content-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*--------------------------------------------------------- SWIPER ---------------------------------------------------------*/
.swiper-quotes {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    padding: 150px 0;
    height: auto !important;
}

.swiper-pagination {
    top: 50% !important;
    left: 20% !important;
    -webkit-transform: translate(0, -50%) !important;
    -moz-transform: translate(0, -50%) !important;
    -ms-transform: translate(0, -50%) !important;
    -o-transform: translate(0, -50%) !important;
    transform: translate(0, -50%) !important;
    bottom: auto !important;
}

.swiper-pagination > .swiper-pagination-bullet {
    display: block !important;
    width: 10px;
    height: 10px;
}

.swiper-pagination > .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 25px !important;
}

.swiper-pagination-bullet span {
    display: none;
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-radius: 50%;
    position: relative;
    top: -5px;
    left: -5px;
}

.swiper-pagination-bullet.active span {
    display: block !important;
}

.text-quote + .link-item {
    margin-top: 120px;
}

.blockquote-empty {
    width: 100%;
    height: 0px;
    padding-bottom: 40%;
}

/*--------------------------------------------------------- MOSAIC ---------------------------------------------------------*/
.mosaic a[style] {
    height: auto !important;
}

.mosaic a[style] img[style] {
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
}

/*--------------------------------------------------------- PROJECT ---------------------------------------------------------*/
#project-nav {
    border-bottom: 1px solid;
}

.project-container {
    display: none;
    margin-bottom: 15px;
}

#project-nav .accordion-item:not(:last-child) .project-container {
    margin-bottom: 50px;
}

.project-highlights > .content-wrapper > .row {
    margin-top: -15px;
    margin-bottom: -15px;
}

.project-highlights > .content-wrapper > .row > [class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
}

.project-highlights .project-card {
    min-height: 100%;
}

.project-card-img,
.project-container figure {
    padding-top: 70%;
    height: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.project-card-img:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.project-card-container {
    padding: 50px 70px;
}

.header-project,
.accordion-menu > .accordion-item > header {
    padding: 15px 40px 15px 0;
    border-top: 1px solid;
    position: relative;
}

.header-project .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(-10px, -50%);
    -moz-transform: translate(-10px, -50%);
    -ms-transform: translate(-10px, -50%);
    -o-transform: translate(-10px, -50%);
    transform: translate(-10px, -50%);
}

html.no-touchevents .header-project:hover .arrow {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.project-box {
    cursor: pointer;

    &.active {
        cursor: default;
    }

    img {
        width: 100%;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }
}

.project-container figure {
    margin-top: 20px;
}

#content-project table {
    width: 100%;
    border: none;
    border-collapse: collapse;
    margin: -5px 0;
}

#content-project tr,
#content-project td {
    margin: 0;
}

#content-project tr {
    padding: 0;
}

#content-project td {
    padding: 5px 0;
}

#content-project td:first-child {
    vertical-align: top;
    white-space: nowrap;
}

#content-project td:last-child {
    vertical-align: bottom;
}

.content-project.active {
    display: block !important;
}

.project-programme {
    margin-top: 15px;
}

#contains-project {
    min-height: 400px;
}

body.loading #contains-project {
    background: url('../images/loading-grey.gif') no-repeat center 80%;
    background-size: 50px auto;
}

.project-description,
.project-gallery > *:not(:last-child) {
    margin-bottom: 50px;
}

.project-gallery > img {
    display: block;
    width: 100%;
}

/*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
.introduction + .formation {

    #formation-search {
        transform: translate(0, -100%);
    }
}

.fancybox-container {

    .fancybox-content {
        padding: 0 !important;
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
    }
}

.fancybox-button svg {
    width: 40px !important;
    height: 40px !important;
}

.fancybox-close-small {
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    opacity: 1 !important;
}

.fancybox-slide--html .fancybox-close-small {
    right: 55px !important;
    top: 55px !important;
}

.fancybox-slide.fancybox-slide--iframe {
    padding: 100px 0 0;
}

.fancybox-slide--iframe .fancybox-close-small {
    right: 30px !important;
    top: -70px !important;
}

.error-message {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 50px 15px;
}

/*--------------------------------------------------------- SERVICES RESUME ---------------------------------------------------------*/
.resume > .content-wrapper > .row,
.resume-long > .content-wrapper > .row {
    margin-top: -15px;
    margin-bottom: -15px;
}

.resume .services-box,
.resume-long .services-box {
    padding-top: 15px;
    padding-bottom: 15px;
}

.resume-long-wrapper {
    min-height: 100%;
}

.services-card-img {
    padding-top: 50%;
    height: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.resume-long-container {
    padding: 100px 135px;
}

.project-card:hover .project-img,
.services-box:hover .service-img {
    -webkit-transform: scale(1.03) translate3d(0, 0, 0);
    -moz-transform: scale(1.03) translate3d(0, 0, 0);
    -ms-transform: scale(1.03) translate3d(0, 0, 0);
    -o-transform: scale(1.03) translate3d(0, 0, 0);
    transform: scale(1.03) translate3d(0, 0, 0);
}

/*--------------------------------------------------------- GOOGLE MAPS ---------------------------------------------------------*/
.acf-map-wrapper {
    position: relative;
    height: 100%;
}

.acf-map {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    z-index: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.acf-map {
    .gmnoprint:not(.gm-bundled-control),
    .gm-style-cc {
        display: none;
    }
    .gmnoprint.gm-bundled-control {
        .gmnoprint {
            display: block;
        }
    }
}

.acf-map .gm-style .gm-style-iw-c {
    padding: 20px !important;
}

.acf-map .gm-style .gm-style-iw-d {
    overflow: visible !important;
    max-height: none !important;
}

.map a {
    display: block;
}

.btn-maps-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 100;
    padding: 15px 70px 15px 25px;
}

.btn-maps-mobile::after {
    content: "";
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    right: 25px;
    top: 50%;
    background-image: url('../images/icon-map-white.svg');
    background-size: 26px 26px;
    margin-top: -13.5px;
    width: 26px;
    height: 26px;
}

.address-wrapper {
    margin-top: -15px;
    margin-bottom: -15px;

    &.grid-block{
        height: auto !important;
    }

    .block-item {

        p span {
            display: block;
        }
    }

    &:not(.grid-block) > [class*="col"] {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.logo-wrapper.format_l {

    &.logo-nb-1,
    &.logo-nb-2 {

        .logo-item {
            min-height: 150px;

            img {
                max-width: 250px;
                max-height: 150px;
            }
        }
    }
}


/*--------------------------------------------------------- TABLE ---------------------------------------------------------*/
.formation-cat {

    &:nth-last-child(n+2) {
        padding-bottom: 75px;
    }

    & > .row {
        margin-top: -15px;
        margin-bottom: -15px;

        & > .formation-items {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.formation-items {
    position: relative;

    &.old {
        width: 100%;
        border-top: 1px solid #000;
        position: relative;
        padding: 25px 15px;
    }

    &-cell {
        width: 100%;
        border-top: 1px solid;
        padding: 25px 15px;

        &:hover .arrow {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }

    &-card {
        position: relative !important;
        left: auto !important;
        top: auto !important;
        transform: none !important;
        display: -ms-flexbox;
        display: flex;

        .formation-card {

            &-image {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 50%;
                overflow: hidden;

                .bg-section.placeholder {
                    transform: scale(1.2);
                    filter: blur(20px);
                }
            }

            &-details {
                padding: 20px;
            }
        }
    }
}

#faq-search,
#formation-search {
    position: relative;
    width: 100%;
    height: 60px;
}

.arrow,
.category-header .arrow-accordion,
.question-header .open-question,
.dropdown-label::after {
    content: '';
    background: url(../images/arrow-table.svg) no-repeat;
    width: 18px;
    height: 18px;
}

.search-icon {
    content: '';
    background: url(../images/search-table.svg) no-repeat;
    width: 21px;
    height: 20px;
    z-index: 10;
}

.arrow {
    display: block;
    -webkit-transform: translate(-10px, 0);
    -moz-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
}

.search-icon {
    right: 16px;
    top: 50%;
    position: absolute;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

#formation-filter {
    z-index: 8888;
}

.row-search input,
.dropdown-wrapper,
.dropdown-label {
    position: relative;
    width: 100%;
    height: 60px;
}

.row-search input,
.dropdown-label {
    border: 1px solid;
    padding: 19px 45px 15px 20px;
}

.row-search input {
    z-index: 100;
}

.dropdown-label {
    min-width: 250px;
    cursor: pointer;
}

.question-header .open-question,
.category-header .arrow-accordion,
.dropdown-label::after {
    display: inline-block;
    right: 15px;
    top: 50%;
    margin-top: -2px;
    position: absolute;
    -webkit-transform: rotateZ(90deg) translate(-50%, 0);
    -moz-transform: rotateZ(90deg) translate(-50%, 0);
    -ms-transform: rotateZ(90deg);
    -o-transform: rotateZ(90deg) translate(-50%, 0);
    transform: rotateZ(90deg) translate(-50%, 0);
}

.category-faq.active .category-header .arrow-accordion,
.question-item.active .question-header .open-question,
.dropdown-wrapper.active .dropdown-label::after,
.class-test {
    -webkit-transform: rotate(-90deg) translate(50%, 0) !important;
    -moz-transform: rotate(-90deg) translate(50%, 0) !important;
    -ms-transform: rotate(-90deg) !important;
    -o-transform: rotate(-90deg) translate(50%, 0) !important;
    transform: rotate(-90deg) translate(50%, 0) !important;
}

.dropdown-menu {
    position: relative;
    padding: 10px 0;
    z-index: 99999;
}

.dropdown-menu a {
    display: block;
    padding: 10px 15px;
    cursor: pointer;
}

/*--------------------------------------------------------- FAQ ---------------------------------------------------------*/
.accordion-menu > .accordion-item > header,
.accordion-item.question-item,
.accordion-item.category-faq {
    border-top: 1px solid;
}

.accordion-menu > .accordion-item > header,
#faq-wrapper:not(.empty) {
    border-bottom: 1px solid;
}

.question-header,
.question-container,
.category-container,
.category-header {
    position: relative;
    padding: 23px 10px 20px;
}

.question-header,
.category-header {
    cursor: pointer;
    padding-right: 65px;
}

.category-container {
    padding-bottom: 0;
}

#faq-wrapper {
    height: auto !important;
}

#faq-wrapper li {
    position: relative !important;
    left: auto !important;
    top: auto !important;
    transform: none !important;
}


/*--------------------------------------------------------- TEAM ---------------------------------------------------------*/
section.introduction + section.team-list {
    overflow: visible;
    z-index: 100;
}

#team-grid {
    width: 100%;
    z-index: 10;
    margin-top: -15px;
    margin-bottom: -15px;
}

.team-card-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.team-card-item img {
    display: block;
}

.team-filter-wrapper {
    position: relative;
    z-index: 100;
}

.team-card-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 130%;
    overflow: hidden;
}

.team-card-image.no-image {
    background: url('../images/vertical-access-icon-white.svg') no-repeat center center;
    background-size: 20% auto;
}

.team-card-image img {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-44%, -3%);
    -moz-transform: translate(-44%, -3%);
    -ms-transform: translate(-44%, -3%);
    -o-transform: translate(-44%, -3%);
    transform: translate(-44%, -3%);
    width: auto;
    max-width: none;
    height: 180%;
}

.team-card-item .list-icons {
    margin-top: 5px;
}

.team-card-item .btn-rounded {
    font-size: 80%;
    line-height: 1em;
}

/*--------------------------------------------------------- TEAM MASONRY ---------------------------------------------------------*/
#team-grid-masonry {
    margin: -7.5px;
}

.grid-sizer-team {
    width: 8.333%;
}

#team-grid-masonry .team-card-wrapper {
    width: 25%;
    height: 0;
    padding-bottom: 20%;
}

#team-grid-masonry .team-card-wrapper.size-big {
    width: 50%;
    padding-bottom: 40%;
}

#team-grid-masonry.filter .team-card-wrapper {
    width: 33.333%;
    padding-bottom: 30%;
}

#team-grid-masonry .team-card-item,
#team-grid-masonry .team-card-info-wrapper {
    position: absolute;
}

#team-grid-masonry .team-card-item {
    left: 7.5px;
    right: 7.5px;
    top: 7.5px;
    bottom: 7.5px;
    padding: 0;
}

#team-grid-masonry .team-card-image {
    background-position: left center;
    background-image: none;
    height: 100%;
    padding-bottom: 0;
}

#team-grid-masonry .team-card-info-wrapper {
    left: 0;
    bottom: 0;
    padding: 15px;
    width: 100%;
    height: 100%;
}

/*--------------------------------------------------------- GALLERY ---------------------------------------------------------*/
.grid-sizer-gallery {
    width: 8.333%;
}

.grid-item-gallery {
    width: 33.333%;
    padding-bottom: 20%;
    position: relative;
}

.grid-item-gallery.big {
    width: 50%;
    padding-bottom: 40%;
}

.grid-item-gallery.medium {
    width: 50%;
    padding-bottom: 20%;
}

/*--------------------------------------------------------- PRESS MOSAIC ---------------------------------------------------------*/
.mosaic img {
    display: block;
    position: relative;
    width: 50%;
    height: auto !important;
    z-index: 0;
}

/*--------------------------------------------------------- FORMS ---------------------------------------------------------*/
form, fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

input, textarea, select, button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    border: 1px solid;
    padding: 10px 20px;
    width: 100%;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none !important;
}

textarea {
    resize: none;
    height: 180px;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

input[type="submit"] {
    border: none;
    display: block;
    width: auto;
}

select::-ms-expand {
    display: none;
}

.row-form {
    position: relative;
    width: 100%;
    margin: 10px 0;
}

.row-form + .row-form {
    margin-top: 20px;
}

form .row {
    margin-left: -10px;
    margin-right: -10px;
}

form [class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
}

.row-search {
    margin: 0;
}

.row-form > label {
    margin: 0;
    padding: 20px 0 0 20px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}

.row-form.row-form-textarea > label {
    left: 1px;
    right: 1px;
    top: 1px;
    padding-bottom: 7px;
}

.row-form.focus-label label:not(.no-effect) {
    font-size: 11px;
    padding-top: 15px !important;
}

.row-form.row-form-textarea.focus-label > label:not(.no-effect) {
    z-index: 100;
}

.row-form:not(.row-form-submit):not(.row-search) input, .row-form select, .row-form textarea {
    display: block;
    position: relative;
    margin: 0;
    padding: 30px 20px 10px;
    z-index: 1;
}

.row-form textarea {
    padding-top: 40px;
    padding-bottom: 20px;
}

.row-form-submit {
    display: block;
    position: relative;
}

.row-form-submit input {
    position: relative;
    margin: 0;
    z-index: 10;
}

div.wpcf7 {
    .link-item {
        .ajax-loader {
            background-image: url('../images/loading.gif');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 26px auto;
            position: absolute;
            top: 3px;
            right: 4px;
            margin: 0;
            width: 32px;
            height: 32px;
            opacity: 1;
            z-index: 0;

            &:before {
                display: none;
            }
        }
    }

    form.submitting {
        .link-item {
            .ajax-loader {
                z-index: 100;
            }
        }
    }
}

.row-form-select label {
    width: 100%;
    padding-right: 55px;
}

.row-form-select select {
    cursor: pointer;
    position: relative;
    z-index: 99;
}

.row-form-select label:before {
    display: block;
    border-left: 3px solid;
    border-bottom: 3px solid;
    position: absolute;
    right: 15px;
    top: 50%;
    height: 50%;
    padding: 0;
    line-height: 15px;
    z-index: 0;
}

.row-form-checkbox .wpcf7-list-item,
.row-form-checkbox input {
    margin: 0;
    padding: 0;
}

.row-form-checkbox label,
.row-form-radio label {
    border: 1px solid;
    display: block;
    position: relative;
    margin: 0 0 5px;
    padding: 24px 20px;
    cursor: pointer;
}

.row-form-checkbox label:before,
.row-form-checkbox label:after,
.row-form-radio label:before {
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0;
}

.row-form-checkbox label:before,
.row-form-radio label:before {
    content: "";
    border: 1px solid;
}

.row-form-checkbox.checked label:after {
    content: "\00d7";
    margin-left: .5px;
    line-height: 1em;
    text-align: center;
}

.row-form-checkbox .wpcf7-checkbox,
.row-form-checkbox #noanswer,
.row-form-radio input,
.row-form-icon input, .row-form-icon .wpcf7-list-item-label {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: -1;
}

.row-form-checkbox .label-text,
.row-form-radio .wpcf7-list-item-label {
    display: block;
    padding-left: 37px;
}

.row-form-checkbox .if-checked,
.row-form-checkbox.checked .if-unchecked,
.row-form-checkbox .wpcf7-list-item-label {
    display: none;
}

.row-form-checkbox.checked .if-checked {
    display: inline-block;
}

.row-form-radio .wpcf7-form-control-wrap,
.row-form-radio .wpcf7-form-control,
.row-form-radio .wpcf7-list-item,
.row-form-radio label,
.row-form-icon .wpcf7-form-control-wrap,
.row-form-icon .wpcf7-form-control,
.row-form-icon .wpcf7-list-item,
.row-form-icon label {
    display: block;
    position: relative;
}

.row-form-radio .wpcf7-list-item {
    margin: 0 0 10px;
}

.row-form-radio .wpcf7-list-item.last {
    margin-bottom: 0;
}

div#mfcf7_zl_multifilecontainer {
    margin: 0 !important;
}

div#mfcf7_zl_multifilecontainer p {
    margin: 0 0 30px !important;
}


.row-form .wpcf7-form-control-wrap {
    display: block;
}

.row-form span.wpcf7-not-valid-tip {
    position: relative;
    padding: 5px 0 0 0;
}

div.wpcf7 div.wpcf7-response-output {
    position: relative;
    border: none;
    margin: 30px 0 0;
    padding: 15px;
}

.grecaptcha-badge, .grecaptcha-badge * {
    width: 0 !important;
    height: 0 !important;
    left: 0 !important;
    right: auto !important;
    top: 0 !important;
    bottom: auto !important;
    box-shadow: none !important;
    visibility: hidden;
    opacity: 0 !important;
    overflow: hidden;
    z-index: -9999 !important;
}

/*--------------------------------------------------------- FOOTER ---------------------------------------------------------*/
#footer {
    overflow: hidden;
}

#footer,
#footer .logo-footer,
#footer .credits {
    position: relative;
    z-index: 100;
}

#footer .logo-footer a,
#footer .logo-footer img {
    display: block;
    width: 100%;
}

#footer .content-wrapper .bg-section {
    left: auto;
    right: 0;
    top: -1000%;
    width: 1000000%;
    height: 1000000%;
}

.logo-footer img {
    width: 100%;
    height: auto;
}

.logo-cartouche {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.pre-footer {
    padding-top: 130px;
}

#footer a {
    display: block;
}

/*--------------------------------------------------------- OPACITY ---------------------------------------------------------*/
.opacity-0,
.mobile-menu,
#main-menu .menu-item:not(.hover-step2) .sub-menu,
body.menu-opened-step2 .menu-line-3 {
    opacity: 0.0;
    filter: alpha(opacity=0);
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0.0;
    -khtml-opacity: 0.0;
}

.opacity-40,
.category-faq.active .category-header,
.project-box:not(.active) .header-project {
    opacity: 0.4 !important;
    filter: alpha(opacity=40) !important;
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=40)" !important;
    -moz-opacity: 0.4 !important;
    -khtml-opacity: 0.4 !important;
}

.opacity-6 {
    opacity: 0.6 !important;
    filter: alpha(opacity=60) !important;
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=60)" !important;
    -moz-opacity: 0.6 !important;
    -khtml-opacity: 0.6 !important;
}

.fancybox-is-open .fancybox-bg {
    opacity: .95 !important;
    filter: alpha(opacity=95) !important;
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=95)" !important;
    -moz-opacity: .95 !important;
    -khtml-opacity: .95 !important;
}

.opacity-100,
.focus-label span,
body.menu-opened-step2 .mobile-menu,
body.page-scrolled.scrolling-to-top:not(.end-page) .contact-mobile.opacity-0,
body.menu-opened-step2 .contact-mobile.opacity-0,
.swiper-pagination-bullet,
html.no-touchevents .project-box:not(.active) .header-project:hover,
html.no-touchevents .project-box:not(.active) .header-project:hover .arrow.opacity-0,
html.no-touchevents .team-card-wrapper:hover .team-card-info-wrapper.opacity-0,
html.touchevents .team-card-wrapper.hover .team-card-info-wrapper.opacity-0 {
    opacity: 1.0 !important;
    filter: alpha(opacity=100) !important;
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
    -moz-opacity: 1.0 !important;
    -khtml-opacity: 1.0 !important;
}