#main a,
#main button,
.focus-label,
.resume-short,
.services-box .service-img,
.project-card .project-img,
.question-item .question-header .open-question,
.filter-item,
.row-form label, .row-form input, .row-form select, .row-form textarea{
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}
.link-item-nolist a::after,
.link-item span::after,
.link-item a::after,
.link-item > span input,
div.wpcf7 .link-item .ajax-loader,
.arrow,
.project-box .arrow::after,
.project-box header,
.menu-item a,
.sub-menu,
.mobile-menu,
#menu-burger,
.menu-line,
.menu-item a::before,
.project-card,
.resume-long-wrapper,
.category-faq .category-header .arrow-accordion,
html.no-touchevents .resume-short:hover .link-item.green::after,
.swiper-pagination-bullet,
.swiper-pagination-bullet span,
.team-card-info-wrapper{
  -webkit-transition: all .3s cubic-bezier(.7, .0, .3, 1);
  -moz-transition: all .3s cubic-bezier(.7, .0, .3, 1);
  -ms-transition: all .3s cubic-bezier(.7, .0, .3, 1);
  -o-transition: all .3s cubic-bezier(.7, .0, .3, 1);
  transition: all .3s cubic-bezier(.7, .0, .3, 1);
}
.btn-corpo,
#header,
.accordion-menu,
#mobile-menu,
.contact-mobile,
.download-item-container,
#menu-background-burger,
.dropdown-wrapper .dropdown-label::after,
.logo-slider-wrapper{
  -webkit-transition: all .5s cubic-bezier(.7, .0, .3, 1);
  -moz-transition: all .5s cubic-bezier(.7, .0, .3, 1);
  -ms-transition: all .5s cubic-bezier(.7, .0, .3, 1);
  -o-transition: all .5s cubic-bezier(.7, .0, .3, 1);
  transition: all .5s cubic-bezier(.7, .0, .3, 1);
}
body.menu-opened .mobile-menu,
.services-box:hover .service-img,
.project-card:hover .project-img,
.scroll-anim{
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease-in-out;
  -ms-transition: all .7s ease-in-out;
  -o-transition: all .7s ease-in-out;
  transition: all .7s ease-in-out;
}
