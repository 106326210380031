@media(min-width: 1801px) {
  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .content-wrapper {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }


  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .fancybox-content > section {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media(max-width: 1800px) {
  /*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
  .footer-services-item h2, .footer-services-item .style-h2,
  .map h2, .map .style-h2 {
    font-size: 35px;
  }

  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .content-wrapper {
    width: auto;
  }
  .content-wrapper {
    margin-left: 100px;
  }
  .content-wrapper {
    margin-right: 100px;
  }

  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .resume-long-container,
  .project-card-container,
  .services-container {
    padding: 50px 70px;
  }

  section.text-bg-fullwidth {
    padding-top: 150px;
  }
  .pre-footer {
    padding-top: 75px;
  }
  section.title:first-child {
    padding-top: 50px;
  }
  section.text-bg-fullwidth {
    padding-bottom: 150px;
  }

  /*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
  #main-menu > ul > li:not(:last-child) {
    margin-right: 50px;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-wrapper.format_l {
    margin-left: 70px;
    margin-right: 70px;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .fancybox-content > section {
    padding: 100px 0;
  }


  /*--------------------------------------------------------- SERVICES FOOTER ---------------------------------------------------------*/
  .footer-services figure .bg-section {
    width: calc(((100vw - 200px) / 3) + 100px);
    left: auto;
    right: 0;
  }
}

@media(min-width: 1401px) {
  /*--------------------------------------------------------- TEAM MASONRY ---------------------------------------------------------*/
  #team-grid-masonry .team-card-wrapper.size-big .team-card-info-wrapper {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media(max-width: 1400px) {
  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .content-wrapper {
    margin-left: 50px;
  }
  .content-wrapper {
    margin-right: 50px;
  }

  section.title:first-child {
    padding-top: 0;
  }

  /*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
  #footer-menu > ul > li:not(:last-child),
  #main-menu > ul > li:not(:last-child) {
    margin-right: 40px;
  }
  #contact-header > ul > li:not(:last-child) {
    margin-right: 20px;
  }

  /*--------------------------------------------------------- TEAM MASONRY ---------------------------------------------------------*/
  .grid-sizer-team,
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    width: 33.333%;
  }
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    padding-bottom: 30%;
  }
}

@media(min-width: 1200px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-xl-up {
    display: none !important;
  }

  /*--------------------------------------------------------- CONTENU EN BLOCK ---------------------------------------------------------*/
  .block-img-content-wrapper {
    min-height: 400px;
  }

  /*--------------------------------------------------------- TEAM ---------------------------------------------------------*/
  section.introduction + section.team-list:not(.no-filter) {
    padding-top: 0;
  }
  section.introduction + section.team-list .team-filter-wrapper {
    transform: translate(0, -90px);
  }
  section.introduction + section.team-list .team-filter-wrapper {
    margin-bottom: -82.5px;
  }

  /*--------------------------------------------------------- PROJECT ---------------------------------------------------------*/
  #project-mobile-menu {
    display: block !important;
  }
  body.project-menu-top .accordion-menu {
    position: fixed;
    top: 30px;
    left: auto;
  }

  body.project-menu-top.scrolling-to-top .accordion-menu {
    top: 140px;
  }

  body.project-menu-bottom .accordion-menu {
    position: absolute !important;
    top: auto !important;
    bottom: 0;
  }

  .menu-project.scroll-menu .accordion-menu {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .introduction + .formation {
    margin-top: -60px;
  }
}

@media(max-width: 1199px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-lg-down {
    display: none !important;
  }

  .anchor {
    top: 0;
  }

  /*--------------------------------------------------------- COLORS & BACKGROUNDS ---------------------------------------------------------*/
  .footer-services.bg-white {
    background-color: #F3F4F3 !important;
  }

  /*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
  h1, .style-h1 {
    font-size: 50px;
  }
  h2, .style-h2,
  .link-item-nolist a:not(.style-h4),
  .link-item a:not(.style-h4),
  blockquote .style-h1 {
    font-size: 35px;
  }
  h3, .style-h3,
  blockquote .style-h2,
  .slider-quotes .link-item a,
  .map h2, .map .style-h2,
  .fc.size-h5 .fc-button.fc-customTitle-button {
    font-size: 25px;
    line-height: 1.3em;
  }

  .text-left-lg {
    text-align: left !important;
  }
  .text-right-lg {
    text-align: right !important;
  }
  .text-center-lg {
    text-align: center !important;
  }

  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-left: 30px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-right: 30px;
  }
  section,
  #footer .content-wrapper {
    padding-top: 100px;
  }
  .block-img-content-wrapper {
    padding-top: 70px;
  }
  section.introduction.padding-blocks {
    padding-bottom: 200px;
  }
  section,
  .text-footer-services {
    padding-bottom: 100px;
  }
  .team-filter-wrapper {
    padding-bottom: 75px;
  }
  .block-img-content-wrapper {
    padding-bottom: 70px;
  }
  #project-intro {
    padding-bottom: 50px;
  }

  .project-card-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .content-column + .resume-long.bg-light {
    margin-top: -50px;
  }

  /*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
  #main-menu > ul > li:not(:last-child) {
    margin-right: 30px;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-wrapper.format_l {
    margin-left: 0;
    margin-right: 0;

    & > .row {
      margin-top: -30px;
      margin-bottom: -30px;

      .logo-item {
        width: 15%;
        max-width: 15%;
        margin-top: 30px;
        margin-bottom: 30px;

        img {
          max-width: 115px;
          max-height: 72px;
        }
      }
    }

    &.logo-nb-1,
    &.logo-nb-2 {

      .logo-item {
        min-height: 120px;

        img {
          max-width: 200px;
          max-height: 120px;
        }
      }
    }
  }

  .logo-slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  /*--------------------------------------------------------- PROJECT ---------------------------------------------------------*/
  body.single #project-intro,
  body.single #project-mobile-menu,
  body.archive .accordion-menu > .accordion-item > header {
    display: none;
  }
  #project-nav .project-container {
    display: none !important;
  }

  body.archive .project-box.active {
    cursor: pointer;
  }

  body.archive #contains-project {
    min-height: 0;
  }

  .accordion-menu > .accordion-item > header {
    margin-top: 2px;
  }

  /*--------------------------------------------------------- SWIPER ---------------------------------------------------------*/
  .swiper-pagination {
    left: 10% !important;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .introduction + .formation #formation-search {
    transform: translate(0, -50%);
  }

  .fancybox-slide--html .fancybox-close-small {
    right: 25px !important;
    top: 25px !important;
  }

  /*--------------------------------------------------------- OPACITY ---------------------------------------------------------*/
  body.archive .project-box .header-project,
  html.no-touchevents body.archive .project-box .header-project:hover .arrow.opacity-0 {
    opacity: 1.0 !important;
    filter: alpha(opacity=100) !important;
    -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
    -moz-opacity: 1.0 !important;
    -khtml-opacity: 1.0 !important;
  }
}

@media(min-width: 992px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-lg-up,
  .formation-page aside > .row > [class*="col-"]:first-child > [class*="padding-"] {
    display: none !important;
  }

  blockquote p:before {
    position: absolute;
    right: 100%;
    top: 0;
  }

  /*--------------------------------------------------------- PROJECT ---------------------------------------------------------*/
  .project-description p {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
}

@media(max-width: 991px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-md-down {
    display: none !important;
  }

  /*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
  .text-bg-fullwidth p,
  section.text-bg-fullwidth .link-item a,
  section.introduction .link-item a,
  .footer-services-item h2, .footer-services-item .style-h2 {
    font-size: 25px;
    line-height: 1.3em;
  }
  h4, .style-h4,
  body,
  .link-item > span input,
  section.content-column .link-item a,
  section.project .link-item a,
  section.formation .link-item a,
  #main-menu ul li,
  .project-card-container span,
  .project-description p.size-h5 {
    font-size: 21px;
  }
  h5, .style-h5,
  .size-h5,
  .pre-footer,
  .adress-footer,
  .resume-long-container p,
  .dropdown-label,
  .question-container,
  .row-form label,
  .row-form.row-search input,
  div.wpcf7 div.wpcf7-response-output {
    font-size: 16px;
  }

  .text-left-md {
    text-align: left !important;
  }
  .text-right-md {
    text-align: right !important;
  }
  .text-center-md {
    text-align: center !important;
  }
  .block-item ul {
    margin-left: 19px;
  }

  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .content-wrapper {
    margin-left: 25px;
  }
  .content-wrapper {
    margin-right: 25px;
  }

  .line-light {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  section.introduction + section.resume-long,
  section.introduction + section.content-block.block-img,
  section.introduction + section.project {
    margin-top: -100px;
  }

  .bg-resume-long {
    margin-top: 100px;
  }

  section,
  #footer .content-wrapper {
    padding-top: 75px;
  }
  section.bg-white:first-child {
    padding-top: 50px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .resume-long-container,
  .project-card-container,
  .services-container {
    padding-top: 25px;
  }
  section.introduction.padding-blocks,
  section.introduction.padding-resume {
    padding-bottom: 150px;
  }
  section,
  .text-footer-services {
    padding-bottom: 75px;
  }
  .team-filter-wrapper {
    padding-bottom: 50px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .resume-long-container,
  .project-card-container,
  .services-container {
    padding-bottom: 25px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-left: 50px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-left: 25px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-right: 50px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-right: 25px;
  }

  .project-description,
  .project-gallery > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .link-item.green::after {
    display: none;
  }

  /*--------------------------------------------------------- LINK BUTTON ---------------------------------------------------------*/
  section.content-column .link-item a::after,
  section.project .link-item a::after,
  section.formation .link-item a::after,
  .slider-quotes .link-item a::after,
  section.text-bg-fullwidth .link-item a::after,
  section.introduction .link-item a::after,
  .link-item a.style-h4::after,
  .wpcf7 .link-item > span::after {
    background-size: auto 70%;
    margin-top: -2px;
    margin-left: -40px;
  }

  section.formation .link-item.bottom a::after {
    margin-top: -6px;
  }

  .link-item > span input {
    padding: 7px 40px 10px 0;
  }

  div.wpcf7 .link-item .ajax-loader {
    background-size: 20px auto;
    top: 7px;
    right: 3px;
    width: 22px;
    height: 22px;
  }

  /*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
  body {
    padding-top: 162px;
  }
  #header {
    padding: 40px 0;
  }
  body.page-scrolled #header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  body.menu-opened-step2 #header {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #logo img {
    margin-top: -0.44em;
  }

  #contact-header {
    height: auto;
  }

  /*--------------------------------------------------------- TEXTE & ARRIERE-PLAN ---------------------------------------------------------*/
  .list-link-box {
    margin-top: 250px;
  }

  /*--------------------------------------------------------- CONTENU EN BLOCK ---------------------------------------------------------*/
  .block-img-item figure {
    height: 0;
    padding-bottom: 120%;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-item img {
    max-height: 90px;
  }

  /*--------------------------------------------------------- TEAM MASONRY ---------------------------------------------------------*/
  .grid-sizer-team,
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    width: 50%;
  }
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    padding-bottom: 40%;
  }

  /*--------------------------------------------------------- SWIPER ---------------------------------------------------------*/
  .swiper-pagination {
    top: inherit !important;
    left: 25px !important;
    transform: none !important;
    bottom: 25px !important;
    text-align: left !important;
  }
  .swiper-pagination > .swiper-pagination-bullet {
    display: inline-block !important;
  }
  .swiper-pagination > .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 0px !important;
    margin-right: 15px !important;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .fancybox-content > section {
    padding-top: 75px;
  }

  .fancybox-slide--html .fancybox-close-small {
    right: 15px !important;
    top: 15px !important;
  }
}

@media(min-width: 768px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-md-up {
    display: none !important;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .introduction + .formation {
    padding-top: 0;
  }

  /*--------------------------------------------------------- FOOTER ---------------------------------------------------------*/
  #footer .logo-footer {
    width: 77%;
  }
}

@media(max-width: 767px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-sm-down {
    display: none !important;
  }

  .margin-md-b {
    margin-bottom: 40px !important;
  }
  section.introduction + section.resume-long {
    margin-top: 0px;
  }

  /*--------------------------------------------------------- COLORS & BACKGROUNDS ---------------------------------------------------------*/
  .pre-footer {
    background-color: #FFF !important;
  }

  #footer {
    border-color: #DBE5DB !important;
  }

  /*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
  h1, .style-h1 {
    font-size: 36px;
  }
  h2, .style-h2,
  .link-item-nolist a:not(.style-h4),
  .link-item a:not(.style-h4),
  blockquote .style-h1 {
    font-size: 27px;
  }
  h3, .style-h3,
  blockquote .style-h2,
  .slider-quotes .link-item a,
  section.text-bg-fullwidth .link-item a,
  section.introduction .link-item a,
  .text-bg-fullwidth p,
  .text-footer p,
  .map h2, .map .style-h2,
  .fc.size-h5 .fc-button.fc-customTitle-button {
    font-size: 21px;
    line-height: 1.3em;
  }
  h4, .style-h4,
  body,
  .link-item > span input,
  section.content-column .link-item a,
  section.project .link-item a,
  section.formation .link-item a,
  #main-menu ul li,
  .project-card-container span,
  h5, .style-h5,
  .size-h5,
  .project-description p.size-h5,
  .pre-footer,
  .adress-footer,
  .resume-long-container p,
  .dropdown-label,
  .question-container,
  .row-form label,
  .row-form.row-search input,
  div.wpcf7 div.wpcf7-response-output {
    font-size: 16px;
  }
  h6, .style-h6,
  .formation-price small,
  .btn-corpo,
  .dropdown-menu,
  .row-form input, .row-form select, .row-form textarea {
    font-size: 13px;
    line-height: 1.5em;
  }
  small, .small,
  .row-form span.wpcf7-not-valid-tip {
    font-size: 10px;
    line-height: 1.5em;
  }

  #header {
    font-size: 0;
    line-height: 0;
  }

  .logo-wrapper.format_l,
  .logo-slider .logo-item img {

    & > .row {

      .logo-item {
        min-height: 60px;

        img {
          max-width: 80px;
          max-height: 60px;
        }
      }
    }
  }

  .text-left-sm {
    text-align: left !important;
  }
  .text-right-sm {
    text-align: right !important;
  }
  .text-center-sm {
    text-align: center !important;
  }
  .block-item ul {
    margin-left: 15px;
  }

  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .content-wrapper {
    margin-left: 15px;
  }
  .content-wrapper {
    margin-right: 15px;
  }

  .dl-wrapper {
    margin: -7.5px;
  }
  .dl-items {
    padding: 7.5px;
  }

  .line-light {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .grid-item-block .block-item {
    padding-left: 75px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-left: 30px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-left: 15px;
  }
  .grid-item-block .block-item {
    padding-right: 75px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-right: 30px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .block-img-content-wrapper,
  .services-container {
    padding-right: 15px;
  }

  section.text-bg-fullwidth,
  .swiper-slide {
    padding-top: 100px;
  }
  section.title:first-child,
  section.introduction.bg-white + section.bg-light,
  #footer .content-wrapper,
  .pre-footer {
    padding-top: 50px;
  }
  section.bg-light.content-block.block-img + section.bg-light {
    padding-top: 50px !important;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-top: 15px;
  }
  section.text-bg-fullwidth,
  .swiper-slide {
    padding-bottom: 100px;
  }
  section.introduction.bg-white:not(.padding-blocks),
  .text-footer-services,
  .pre-footer,
  section.footer-services {
    padding-bottom: 50px;
  }
  .download-item-container,
  .download-item-container.one-item,
  .resume-long-container,
  .project-card-container {
    padding-bottom: 15px;
  }
  .content-block.block-img {
    padding-bottom: 7.5px;
  }
  #footer .content-wrapper {
    padding-bottom: 0;
  }

  /*--------------------------------------------------------- HEADER ---------------------------------------------------------*/
  body {
    padding-top: 91px;
  }
  #header {
    padding: 30px 65px 30px 0;
  }
  body.page-scrolled:not(.menu-opened-step2) #header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #header-logo {
    display: inline-block;
    width: 100%;
    max-width: 250px;
  }
  #logo img {
    margin-top: 0;
  }

  #contact-header > ul > li {
    margin-top: 0;
  }
  #contact-header > ul > li:not(:last-child) {
    margin-right: 25px;
  }

  /*--------------------------------------------------------- TEXTE & ARRIERE-PLAN ---------------------------------------------------------*/
  .list-link-box {
    margin-top: 120px;
  }

  .text-right .link-item {
    padding-right: 0;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-wrapper {

    .row {
      margin: -15px;
    }
  }
  .logo-item {
    max-width: 25%;
    max-height: 70px;
  }
  .logo-item img {
    max-height: 80px;
  }

  .logo-wrapper.format_l {

    & > .row {

      .logo-item {
        width: 28%;
        max-width: 28%;
      }
    }
  }

  /*--------------------------------------------------------- SERVICES ---------------------------------------------------------*/
  .resume-long > .content-wrapper > .row,
  .project-highlights > .content-wrapper > .row {
    margin: -7.5px;
  }
  .resume-long .services-box,
  .project-highlights > .content-wrapper > .row > [class*="col-"] {
    padding: 7.5px;
  }

  /*--------------------------------------------------------- MOSAIC ---------------------------------------------------------*/
  .mosaic,
  .mosaic a, .mosaic a[style],
  .mosaic img, .mosaic a[style] img[style] {
    height: auto !important;
  }
  .mosaic,
  .mosaic img, .mosaic a[style] img[style] {
    float: none !important;
  }
  .mosaic {
    margin: -7.5px !important;
    width: auto !important;
    overflow: visible !important;
  }
  .mosaic a, .mosaic a[style] {
    float: left !important;
    display: block !important;
    width: auto !important;
    margin: 7.5px !important;
  }
  .mosaic img, .mosaic a[style] img[style] {
    width: auto !important;
    max-height: 350px;
    margin: 0 !important;
  }

  /*--------------------------------------------------------- GALLERY ---------------------------------------------------------*/
  .grid-sizer-gallery,
  .grid-item-gallery,
  .grid-item-gallery.medium {
    width: 50%;
  }
  .grid-item-gallery,
  .grid-item-gallery.medium {
    padding-bottom: 40%;
  }
  .grid-item-gallery.big,
  .grid-item-gallery.big + .grid-item-gallery.medium:last-child {
    width: 100%;
    padding-bottom: 80%;
  }

  /*--------------------------------------------------------- FORMATION & FANCYBOX ---------------------------------------------------------*/
  .introduction + .formation #formation-search {
    transform: translate(0, 0);
  }

  /*--------------------------------------------------------- FOOTER ---------------------------------------------------------*/
  #footer {
    border-top: 1px solid;
  }

  .grid-block {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }

  .grid-item-block,
  .grid-sizer-block {
    width: 100%;
  }
  .block-item {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
  }
}

@media(min-width: 576px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .hidden-sm-up {
    display: none !important;
  }

  /*--------------------------------------------------------- LINK BUTTON ---------------------------------------------------------*/
  .text-center > .link-item a {
    margin-left: -70px;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-item:last-child {
    margin-right: auto;
  }
}

@media(max-width: 575px) {
  /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
  .menu-wrapper,
  .hidden-xs-down {
    display: none !important;
  }

  /*--------------------------------------------------------- FONTS ---------------------------------------------------------*/
  .map h2, .map .style-h2 {
    font-size: 16px;
  }

  #content-project td:first-child {
    font-weight: 700;
  }

  .text-left-xs {
    text-align: left !important;
  }
  .text-right-xs {
    text-align: right !important;
  }
  .text-center-xs {
    text-align: center !important;
  }

  /*--------------------------------------------------------- COMMON ---------------------------------------------------------*/
  .download-item-container,
  .download-item-container.one-item,
  .block-item,
  .resume-long-container,
  .project-card-container {
    padding: 15px;
  }

  .team-card-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .grid-item-block .block-item {
    padding-left: 15px;
  }
  .grid-item-block .block-item {
    padding-right: 15px;
  }
  .swiper-slide {
    padding-top: 75px;
  }
  .swiper-slide {
    padding-bottom: 75px;
  }
  .swiper-slide {
    padding-bottom: 75px;
  }

  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-wrapper.format_l {

    & > .row {

      .logo-item {
        margin-left: 5%;
        margin-right: 5%;

        img {
          max-width: 100%;
        }
      }
    }
  }

  /*--------------------------------------------------------- PROJECT ---------------------------------------------------------*/
  #content-project table,
  #content-project tbody,
  #content-project tr,
  #content-project td {
    display: block;
    width: 100%;
  }
  #content-project td:first-child {
    padding-bottom: 0;
  }
  #content-project td:last-child {
    padding-top: 0;
  }

  /*--------------------------------------------------------- MOSAIC ---------------------------------------------------------*/
  .mosaic img, .mosaic a[style] img[style] {
    max-height: 300px;
  }


  /*--------------------------------------------------------- SWIPER ---------------------------------------------------------*/
  .text-quote + .link-item {
    margin-top: 50px;
  }

  /*--------------------------------------------------------- TEAM MASONRY ---------------------------------------------------------*/
  .grid-sizer-team,
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    width: 100%;
  }
  #team-grid-masonry .team-card-wrapper,
  #team-grid-masonry .team-card-wrapper.size-big,
  #team-grid-masonry.filter .team-card-wrapper {
    padding-bottom: 80%;
  }

  #team-grid-masonry .team-card-info-wrapper {
    height: auto;
  }
}

@media(max-width: 350px) {
  /*--------------------------------------------------------- LISTE LOGOS ---------------------------------------------------------*/
  .logo-wrapper.format_l {

    & > .row {
      margin-top: -15px;
      margin-bottom: -15px;

      .logo-item {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  /*--------------------------------------------------------- MOSAIC ---------------------------------------------------------*/
  .mosaic a, .mosaic a[style] {
    float: none !important;
  }
  .mosaic img, .mosaic a[style] img[style] {
    width: 100% !important;
    max-height: none !important;
    margin: 0 !important;
  }
}
